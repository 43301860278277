/* eslint-disable no-console */
import web3 from "util/web3";
import {
  CessionState,
  CSSDAction,
  CSSDCessionState,
  CSSDState,
  PartialClaimAction,
  PartialClaimState,
  PRIVILEGE_CONTROLLER_RECOVERY,
  SSDState,
} from "util/constants";
import { contractCache, getCompanyIdentityProxy } from "services/smartContracts/smartContracts";
import Contracts from "../smartContracts/contracts/contracts";
import { TextDecoder } from "text-encoding";
import { partnerCache } from "services/partnerService";

//
// COMMON FUNCTIONS
//

export async function getPrivilegeGrantedForCompany(idProxyAddr) {
  const idProxy = getCompanyIdentityProxy(idProxyAddr);
  const masterControllerAddr = await idProxy.methods.activeController().call();
  const masterController = new web3.eth.Contract(
    Contracts.masterController.abi,
    masterControllerAddr
  );
  const pmAddr = await masterController.methods.pm().call();
  const companyPM = new web3.eth.Contract(Contracts.IPrivilegeManager.abi, pmAddr);
  const events = await companyPM.getPastEvents("PrivilegeGranted", {
    fromBlock: 0,
    toBlock: "latest",
  });
  const filteredEvents = events.filter((e) => e.returnValues.pId !== PRIVILEGE_CONTROLLER_RECOVERY);
  return {
    events: filteredEvents,
    pmAddr,
  };
}

export function getIdProxyAddr() {
  if (contractCache && contractCache.identityProxy) {
    return contractCache.identityProxy._address;
  }
  return null;
}

export async function getDetailsUrl(idProxyAddr) {
  return await getCompanyIdentityProxy(idProxyAddr)
    .methods.detailsUrl()
    .call()
    .then(formatBackendUrl);
}

export function formatBackendUrl(backendUrl) {
  let backendUrlFormatted;

  if (backendUrl && backendUrl !== "undefined") {
    backendUrlFormatted =
      backendUrl.startsWith("http://") || backendUrl.startsWith("https://")
        ? backendUrl
        : process.env.REACT_APP_SERVER_URL_PREFIX.concat(backendUrl);
  } else {
    backendUrlFormatted = process.env.REACT_APP_SERVER_URL_PREFIX.concat(
      process.env.REACT_APP_SERVER_URL
    );
  }

  return backendUrlFormatted;
}

export function getPlatformManagerEventListener() {
  return contractCache.platformManagerContract.events;
}

export function getSsdManagerEventListener() {
  return contractCache.ssdManagerContract.events;
}

export function getCssdManagerEventListener() {
  return contractCache.cssdManagerContract.events;
}

export function getMasterControllerEventListener() {
  return contractCache.masterController.events;
}

export function getBSSDControllerEventListener() {
  return contractCache.bssdController.events;
}

export function getCSSDControllerEventListener() {
  return contractCache.cssdController.events;
}

export const getSsdState = async (arranger, ssdid) =>
  await contractCache.ssdManagerContract.methods.getSSD(arranger, toHex(ssdid)).call();

export async function getFirstZessionarId(arranger, ssdid) {
  const zessionarId = await contractCache.ssdManagerContract.methods
    .getFirstZessionarId(arranger, toHex(ssdid))
    .call();
  return zessionarId;
}

export const getTerminationState = async (arranger, ssdid) =>
  await contractCache.ssdManagerContract.methods.getTermination(arranger, toHex(ssdid)).call();

export async function getInterestNotificationState(arranger, ssdid, subid) {
  const interestNotificationState = await contractCache.ssdManagerContract.methods
    .getInterestNotification(arranger, toHex(ssdid), toHex(subid))
    .call();
  return interestNotificationState;
}

export async function getCessionState(arranger, ssdid, subid) {
  const cessionState = await contractCache.ssdManagerContract.methods
    .getCession(arranger, toHex(ssdid), toHex(subid))
    .call();
  return cessionState;
}

export const getFourEyesState = async (arranger, ssdid, subid) =>
  await contractCache.bssdController.methods
    .getFourEyesState(arranger, toHex(ssdid), toHex(subid))
    .call();

export async function getMemberData() {
  const fourEyesState = await contractCache.masterController.methods.getMemberData().call();
  return fourEyesState;
}

export async function getFourEyesFlag() {
  if (contractCache.masterController) {
    const fourEyesFlag = await contractCache.masterController.methods.fourEyesFlag().call();
    return fourEyesFlag;
  }
  return false;
}

export async function getInterestNotificationAuditEventFromBc(ssdId, interestNotificationId) {
  return await contractCache.ssdManagerContract.getPastEvents("InterestNotificationAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: {
      interestNotificationId: interestNotificationId,
      id: ssdId,
    },
  });
}

export async function getAbortedCessionsIds(arranger, ssdid) {
  const cessionWithHashes = [];
  const abortedCessions = await contractCache.ssdManagerContract.methods
    .getAbortedCessionsIds(arranger, toHex(ssdid))
    .call();
  for (const cessionId of abortedCessions) {
    if (cessionId !== null) {
      const cession = await getCessionState(arranger, ssdid, toUtf8(cessionId));
      cessionWithHashes.push({
        id: cession.cessionId,
        hash: cession.hash,
        state: cession.state,
      });
    }
  }
  return cessionWithHashes;
}

export async function getCessionAuditEventFromBc(ssdId, cessionId) {
  return await contractCache.ssdManagerContract.getPastEvents("CessionAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: {
      cessionId: cessionId,
      id: ssdId,
    },
  });
}

export async function getCssdCessionEvent(companyAddr) {
  return await contractCache.cssdManagerContract.getPastEvents("CSSDCessionEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: `${CSSDCessionState.OPEN}`, buyer: companyAddr },
  });
}

export async function getAllCssdCessionEvents(companyAddr) {
  console.log("start loading cssd cession events from BC");
  console.log(`companyAddr: ${companyAddr}`);
  const t0 = performance.now();
  const getPastEvents = (filter) => {
    console.log("get past CSSDCessionEvent for: ", filter);
    return contractCache.cssdManagerContract.getPastEvents("CSSDCessionEvent", {
      fromBlock: 0,
      toBlock: "latest",
      filter: { ...filter },
    });
  };
  const filters = [getPastEvents({ seller: companyAddr }), getPastEvents({ buyer: companyAddr })];
  return await Promise.all(filters).then((filteredEvents) => {
    // 2d -> 1d
    const events = Array.prototype.concat.apply([], filteredEvents);
    const t1 = performance.now();
    console.log("finish loading cssd cession events from BC");
    console.log(`loading cssd cession events took: ${Math.round(t1 - t0)} milliseconds`);
    // latest first
    return events.sort((a, b) => b.blockNumber - a.blockNumber);
  });
}

export async function getCssdCessionEventsForSeller(companyAddr) {
  console.log("start loading cssd cession events for seller from BC");
  console.log(`companyAddr: ${companyAddr}`);
  const t0 = performance.now();
  const getPastEvents = (filter) => {
    console.log("get past CSSDCessionEvent for: ", filter);
    return contractCache.cssdManagerContract.getPastEvents("CSSDCessionEvent", {
      fromBlock: 0,
      toBlock: "latest",
      filter: { ...filter },
    });
  };
  const filters = [getPastEvents({ seller: companyAddr })];
  return await Promise.all(filters).then((filteredEvents) => {
    // 2d -> 1d
    const events = Array.prototype.concat.apply([], filteredEvents);
    const t1 = performance.now();
    console.log("finish loading cssd cession events for seller from BC");
    console.log(`loading cssd cession events for seller took: ${Math.round(t1 - t0)} milliseconds`);
    // latest first
    return events.sort((a, b) => b.blockNumber - a.blockNumber);
  });
}

export async function getCSSDFourEyesState(arranger, ssdid, subid) {
  const fourEyesState = await contractCache.cssdController.methods
    .getFourEyesState(arranger, toHex(ssdid), toHex(subid))
    .call();
  return fourEyesState;
}

export async function getCssdEventFromBc(companyAddr, isZSM) {
  console.log("start loading cssd events from BC");
  console.log(`companyAddr: ${companyAddr}, isZSM: ${isZSM}`);
  const t0 = performance.now();
  const getPastEvents = (additionalFilters = {}) => {
    console.log("get past CSSDEvent for: ", additionalFilters);
    return contractCache.cssdManagerContract.getPastEvents("CSSDEvent", {
      fromBlock: 0,
      toBlock: "latest",
      filter: { state: `${CSSDState.NEW}`, ...additionalFilters },
    });
  };
  const filters = isZSM
    ? [getPastEvents({ payingAgent: companyAddr })]
    : [getPastEvents({ seller: companyAddr, state: `${CSSDState.OPEN}` })];
  const [filteredEvents] = await Promise.all(filters);

  const migratedAndConnected = await getPastEvents({
    seller: companyAddr,
    state: `${CSSDState.DOCUMENT_SIGNED_SELLER}`,
  });
  const filteredMigratedAndConnected = [...migratedAndConnected].filter(
    (entry) =>
      entry.returnValues.action === `${CSSDAction.CONNECT}` &&
      entry.returnValues.isMigrated === true
  );
  const result = [...filteredEvents, ...filteredMigratedAndConnected].sort(
    (a, b) => b.blockNumber - a.blockNumber
  );
  const t1 = performance.now();
  console.log("finish loading cssd events from BC");
  console.log(`loading cssd events took: ${Math.round(t1 - t0)} milliseconds`);
  return result;
}

let MIG_STATE = [PartialClaimState.NOTIFIED, PartialClaimState.REPAID, PartialClaimState.CANCELLED];

export async function getPartialClaimEventFromBc(companyAddr, isZSM, fromBlock) {
  console.log("start loading partial claim events from BC");
  console.log(`companyAddr: ${companyAddr}, isZSM: ${isZSM}, fromBlock: ${fromBlock}`);
  const t0 = performance.now();
  const getPastEvents = (filter) => {
    console.log("get past PartialClaimEvent for: ", filter);
    return contractCache.cssdManagerContract.getPastEvents("PartialClaimEvent", {
      fromBlock: fromBlock,
      toBlock: "latest",
      filter: { state: `${PartialClaimState.NEW}`, ...filter },
    });
  };

  let filters = [];
  if (isZSM) {
    for (const member of partnerCache) {
      if (member.idProxyAddress) {
        filters.push(getPastEvents({ seller: member.idProxyAddress }));
      }
    }
    filters.push(getPastEvents({ buyer: companyAddr }));
  } else {
    filters = [
      getPastEvents({ seller: companyAddr, state: `${PartialClaimState.NOTIFIED}` }),
      getPastEvents({ buyer: companyAddr }),
    ];
  }
  const filteredEvents = (await Promise.all(filters)).flat();

  const [migratedAndConnectedBuyer] = await Promise.all(
    MIG_STATE.map((state) =>
      getPastEvents({
        buyer: companyAddr,
        migrated: true,
        state: `${state}`,
      })
    )
  );

  const [migratedAndConnectedSeller] = await Promise.all(
    MIG_STATE.map((state) =>
      getPastEvents({
        seller: companyAddr,
        state: `${state}`,
      })
    )
  );

  const migratedCombined = [...migratedAndConnectedBuyer, ...migratedAndConnectedSeller].filter(
    (entry) =>
      entry.returnValues.action === `${PartialClaimAction.CONNECT}` &&
      entry.returnValues.isMigrated === true
  );

  const result = [...filteredEvents, ...migratedCombined].sort(
    (a, b) => b.blockNumber - a.blockNumber
  );
  const t1 = performance.now();
  console.log("finish loading partial claim events from BC");
  console.log(`loading partial claim events took: ${Math.round(t1 - t0)} milliseconds`);
  return result;
}

export async function getSsdEventsFromBc(companyAddr) {
  //const t0 = performance.now();
  const ssdEventsSeller = await contractCache.ssdManagerContract.getPastEvents("SSDEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: SSDState.NEW, seller: companyAddr },
  });

  const ssdEventsBuyer = await contractCache.ssdManagerContract.getPastEvents("SSDEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: SSDState.NEW, buyer: companyAddr },
  });

  const preparedEventsSeller = await contractCache.ssdManagerContract.getPastEvents("SSDEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: SSDState.PREPARED, seller: companyAddr },
  });

  const preparedEventsBuyer = await contractCache.ssdManagerContract.getPastEvents("SSDEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: SSDState.PREPARED, buyer: companyAddr },
  });

  const preparedRestEventsSeller = await contractCache.ssdManagerContract.getPastEvents(
    "SSDEvent",
    {
      fromBlock: 0,
      toBlock: "latest",
      filter: { state: SSDState.PREPARED_REST, seller: companyAddr },
    }
  );

  const preparedRestEventsBuyer = await contractCache.ssdManagerContract.getPastEvents("SSDEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { state: SSDState.PREPARED_REST, buyer: companyAddr },
  });
  //const t1 = performance.now();

  return [
    ...ssdEventsSeller,
    ...ssdEventsBuyer,
    ...preparedEventsSeller,
    ...preparedEventsBuyer,
    ...preparedRestEventsSeller,
    ...preparedRestEventsBuyer,
  ];
}

// For Bssd Reason Hashes only!
export function getHashFromData(data) {
  return web3.utils.soliditySha3(data);
}

export function getMasterControllerContractMethods() {
  return contractCache.masterController.methods;
}

export function getBSSDControllerContractMethods() {
  return contractCache.bssdController.methods;
}

export function getCSSDControllerContractMethods() {
  return contractCache.cssdController.methods;
}

export function getCompanyIPrivelegeManagerMethods() {
  return contractCache.companyIPmContract.methods;
}

export function getPlatformPrivilegeManagerMethods() {
  return contractCache.privilegeManagerContract.methods;
}

export function getPlatformManagerMethods() {
  return contractCache.platformManagerContract.methods;
}

export function toHex(string) {
  return web3.utils.utf8ToHex(string);
}

export function toSha3(string) {
  return web3.utils.sha3(string);
}

export function ab2str(hashBuffer) {
  return new TextDecoder("utf-8").decode(hashBuffer);
}

export function toUtf8(hex) {
  return web3.utils.hexToUtf8(hex);
}

export function getBlockNumber() {
  return web3.eth.getBlockNumber();
}

export async function getTimestampFromBlock(blockNumber) {
  const timestamp = await web3.eth.getBlock(blockNumber).then((response) => {
    return response.timestamp;
  });
  return timestamp;
}

export async function getAccountBalance(account) {
  await web3.eth.getBalance(account.address);
}

export function isInCompany(companyAddr) {
  return contractCache.identityProxy._address?.toLowerCase() === companyAddr?.toLowerCase();
}

export const checkIfIsAllowedToSeeTransaction = (arranger, buyer, ssdState) => {
  if (
    contractCache.identityProxy._address?.toLowerCase() === arranger?.toLowerCase() &&
    ssdState !== SSDState.PREPARED
  )
    return true;
  return (
    contractCache.identityProxy._address?.toLowerCase() === buyer?.toLowerCase() &&
    ssdState !== SSDState.NEW &&
    ssdState !== SSDState.IN_WORK &&
    ssdState !== SSDState.CANCELLED &&
    ssdState !== SSDState.PREPARED
  );
};

export function checkIfisAllowedToSeeCession(cessionState, wasApproved) {
  if (
    cessionState !== CessionState.INVALID &&
    cessionState !== CessionState.CANCELLED &&
    (cessionState !== CessionState.ABORTED || wasApproved) &&
    cessionState !== CessionState.NEW &&
    cessionState !== CessionState.IN_WORK
  ) {
    return true;
  }
  return false;
}

export async function sendDeployTransaction(account, data) {
  const tx = {
    from: account.address,
    data,
    gasPrice: "0x1",
    gasLimit: "0xb71b00",
  };
  const signedTx = await account.signTransaction(tx);
  return web3.eth
    .sendSignedTransaction(signedTx.rawTransaction)
    .then((receipt) => {
      //eslint-disable-next-line no-console
      console.log(receipt);
      return receipt;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

//signs tx and sends it
export async function sendTransaction(account, to, data) {
  const tx = {
    value: "0x0",
    to,
    from: account.address,
    data,
    gasPrice: "0x1",
    gasLimit: "0xb71b00",
  };
  const signedTx = await account.signTransaction(tx);
  return web3.eth.sendSignedTransaction(signedTx.rawTransaction);
}

async function estimateGasLimit(to, data) {
  return await web3.eth.estimateGas({
    to,
    data,
  });
}

//signs tx and sends it
export async function sendRefuelTransaction(account, to, data) {
  const estimatedGasLimit = await estimateGasLimit(to, data);
  const tx = {
    value: "0x0",
    to,
    from: account.address,
    data,
    gasPrice: "0x1",
    gasLimit: estimatedGasLimit,
  };
  const signedTx = await account.signTransaction(tx);
  return web3.eth.sendSignedTransaction(signedTx.rawTransaction);
}

export const ssdAuditEvents = async (ssdId) => {
  try {
    return await contractCache.ssdManagerContract.getPastEvents("SSDAuditEventV2", {
      fromBlock: 0,
      toBlock: "latest",
      filter: { id: ssdId },
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};

export async function ssdFourEyesEvents(ssdId, subId) {
  const ssdEvents = await contractCache.bssdController.getPastEvents("FourEyesEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { id: ssdId, subid: subId },
  });
  //old bssd FourEyesEvents were in simpleMemberController
  let ssdEventsLegacy = [];
  if (contractCache.simpleMemberController != null) {
    ssdEventsLegacy = await contractCache.simpleMemberController.getPastEvents("FourEyesEvent", {
      fromBlock: 0,
      toBlock: "latest",
      filter: { id: ssdId, subid: subId },
    });
  }
  return [...ssdEvents, ...ssdEventsLegacy];
}

export async function companyDataUpdate(account, hash) {
  try {
    const contractMethods = getMasterControllerContractMethods();
    const data = contractMethods.memberDataUpdate(hash).encodeABI();
    return await sendTransaction(account, contractCache.masterController._address, data);
  } catch (error) {
    console.error(error);
  }
}

export async function companyDataModifyAndApproveUpdate(account, hash) {
  try {
    const contractMethods = getMasterControllerContractMethods();
    const data = contractMethods.memberDataModifyAndApprove(hash).encodeABI();
    return await sendTransaction(account, contractCache.masterController._address, data);
  } catch (error) {
    console.error(error);
  }
}

export async function companyDataApproveUpdate(account, hash) {
  try {
    const contractMethods = getMasterControllerContractMethods();
    const data = contractMethods.memberDataApprove(hash).encodeABI();
    return await sendTransaction(account, contractCache.masterController._address, data);
  } catch (error) {
    console.error(error);
  }
}

export async function companyDataRejectUpdate(account, hash) {
  try {
    const contractMethods = getMasterControllerContractMethods();
    const data = contractMethods.memberDataReject(hash).encodeABI();
    return await sendTransaction(account, contractCache.masterController._address, data);
  } catch (error) {
    console.error(error);
  }
}

export async function getCompanyDataUpdateEvent(companyAddress) {
  const updateEvents = await contractCache.platformManagerContract.getPastEvents(
    "MemberDataUpdatedEvent",
    {
      fromBlock: 0,
      toBlock: "latest",
      filter: { companyAddress },
    }
  );
  return updateEvents;
}
